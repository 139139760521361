import React from 'react'


const Blog = () => {

    return (
        <>

            <section id='client'>
                <div className="container-fluid d-none d-sm-block" style={{ backgroundColor: "#ECECEC" }} >
                    <div className="container p-5 laila-semibold">
                        <div className="row g-4 d-flex">
                            <div className='text-start mb-5' >
                                <div className='' style={{ paddingBottom: "16px" }} >
                                    <h6 className='laila-bold' style={{ fontSize: "15px", lineHeight: "1.2em", color: "#65cef5" }} >OUR CLIENT</h6>
                                </div>
                                <h4 className='laila-bold' style={{ fontSize: "48px", lineHeight: "1.2em", color: "#151515" }} > You’ll love fresh <br /> taste of our natural water </h4>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className='' >
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >S B I Bank</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Bentley System</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >T M F Group</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Income Tax Office</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Mahesh Tutorials </p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='' >
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Big Basket</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Amazon</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Puma</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Tata Motors </p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='' >
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Federal Bank</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >C G Power Solutions</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Open Slate VFX Studio</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Pune Metro Sites & Office</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Awesome Salon</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='' >
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >A J class</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Behere class</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Saraswat bank</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Smile Technology</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Platinum Smart Tech India Pvt ltd</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-sm-none d-block" style={{ backgroundColor: "#ECECEC" }} >
                    <div className="container p-2 pt-5 pb-4 laila-semibold">
                        <div className="row g-4 d-flex">
                            <div className='text-start mb-4' >
                                <div className='' style={{ paddingBottom: "16px" }} >
                                    <h6 className='laila-bold' style={{ fontSize: "15px", lineHeight: "1.2em", color: "#65cef5" }} >OUR CLIENT</h6>
                                </div>
                                <h4 className='laila-bold' style={{ fontSize: "38px", lineHeight: "1.2em", color: "#151515" }} > You’ll love fresh taste of our natural water </h4>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className='' >
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >S B I Bank</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Bentley System</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >T M F Group</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Income Tax Office</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Mahesh Tutorials </p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='' >
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Big Basket</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Amazon</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Puma</p>
                                    <p className='' style={{ color: "#F3360D", fontSize: "16px", fontWeight: "800" }} >Tata Motors </p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='' >
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Federal Bank</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >C G Power Solutions</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Open Slate VFX Studio</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Pune Metro Sites & Office</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Awesome Salon</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='' >
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >A J class</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Behere class</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Saraswat bank</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Smile Technology</p>
                                    <p className='' style={{ color: "#2a6496", fontSize: "16px", }} >Platinum Smart Tech India Pvt ltd</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Blog