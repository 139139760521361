import React from 'react'

const Product = () => {
    return (
        <>

            <section id='product'>
                <div className='container-fluid d-none d-sm-block' style={{ backgroundColor: "#ECF2F6" }} >
                    <div className="container laila-light pb-4" >
                        <div className="row g-4 d-flex justify-content-center  p-lg-3" >
                            <div className='text-center mb-5' >
                                <div className='' style={{ paddingBottom: "16px" }} >
                                    <h6 className='laila-bold' style={{ fontSize: "14px", lineHeight: "1.2em", color: "#65cef5" }} >OUR PRODUCTS</h6>
                                </div>
                                <h4 className='  laila-bold' style={{ fontSize: "48px", lineHeight: "1.2em", color: "#151515" }} > Delivered fresh to your door <br /> by our team. </h4>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a",borderRadius:"10px 10px 0px 0px" }} >
                                    <div className='' style={{padding:"30px"}} >
                                        <img className='w-100 border' style={{ borderRadius: "10px",height:"16rem" }} src={require("../images/200ml.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='text-center p-3 mb-lg-4'>
                                        <h2 className='' style={{ fontSize: "22px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >200 ml Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a",borderRadius:"10px 10px 0px 0px" }} >
                                    <div className='' style={{padding:"30px"}}>
                                        <img className='w-100 border' style={{ borderRadius: "10px",height:"16rem" }} src={require("../images/500ml.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='text-center p-3 mb-lg-4'>
                                        <h2 className='' style={{ fontSize: "22px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >500 ml Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a",borderRadius:"10px 10px 0px 0px" }} >
                                    <div className='' style={{padding:"30px"}}>
                                        <img className='w-100 border' style={{ borderRadius: "10px",height:"16rem" }} src={require("../images/1ltr.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='text-center p-3 mb-lg-4'>
                                        <h2 className='' style={{ fontSize: "22px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >1 Ltr Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a",borderRadius:"10px 10px 0px 0px" }} >
                                    <div className='' style={{padding:"30px"}}>
                                        <img className='w-100 border' style={{ borderRadius: "10px",height:"16rem" }} src={require("../images/20ltr.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='text-center p-3 mb-lg-4'>
                                        <h2 className='' style={{ fontSize: "22px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > 20 Ltr Bottled Water</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid d-sm-none d-block' style={{ backgroundColor: "#ECF2F6" }} >
                    <div className="container  laila-light pb-5" >
                        <div className="row g-4 d-flex justify-content-center  p-lg-3" >
                            <div className='text-center mt-5 mb-4' >
                                <div className='' style={{ paddingBottom: "16px" }} >
                                    <h6 className='laila-bold' style={{ fontSize: "14px", lineHeight: "1.2em", color: "#65cef5" }} >OUR PRODUCTS</h6>
                                </div>
                                <h4 className='  laila-bold' style={{ fontSize: "38px", lineHeight: "1.2em", color: "#151515" }} > Delivered fresh to your door <br /> by our team. </h4>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='p-4'>
                                        <img className='w-100 border' style={{ borderRadius: "10px" }} src={require("../images/200ml.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='text-center p-3'>
                                        <h2 className='' style={{ fontSize: "24px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >200 ml Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='p-4'>
                                        <img className='w-100 border' style={{ borderRadius: "10px" }} src={require("../images/500ml.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='text-center p-3'>
                                        <h2 className='' style={{ fontSize: "24px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >500 ml Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='p-4'>
                                        <img className='w-100 border' style={{ borderRadius: "10px" }} src={require("../images/1ltr.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='text-center p-3'>
                                        <h2 className='' style={{ fontSize: "24px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >1 Ltr Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='p-4'>
                                        <img className='w-100 border' style={{ borderRadius: "10px" }} src={require("../images/20ltr.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='text-center p-3 '>
                                        <h2 className='' style={{ fontSize: "24px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > 20 Ltr Bottled Water</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Product