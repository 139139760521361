import React from 'react'
import { Link } from "react-scroll";


const Footer = () => {
    return (

        <section>
            <div className="container-fluid pt-lg-3 pb-3 d-none d-sm-block" style={{ backgroundColor: "#32409A" }}>
                <div className="container laila-semibold pt-lg-4">
                    <div className="row p-lg-3 g-4 d-flex justify-content-center">

                        <div className="col-lg-3 col-md-6 text-white ">
                            <div className='pb-2'>
                                <Link to='main' style={{ cursor: "pointer" }}>
                                    <img className='' src={require("../images/Logo3.png")} alt="" />
                                </Link>
                            </div>
                            <div className='mt-3 pt-2'>
                                <p className='me-3' style={{ color: "#ccdbeb", fontSize: "17.5px", lineHeight: "27px", fontWeight: "400" }} >
                                    Indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment so blinded by desire all they cannot foresee.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 text-white ">
                            <div className='mb-2' >
                                <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white mt-3' style={{ fontSize: "24px", fontWeight: "800" }} >Useful Links</h6>
                            </div>
                            <div className=' pt-3'>
                                <nav className='h1FontFamily1 ms-lg-3' style={{ fontSize: "17px", }}>
                                    <div className='mt-3 fontFamilyHeader1 ' style={{ color: "#ccdbeb", fontSize: "16px", lineHeight: "19px", fontWeight: "400", cursor: "pointer" }}>
                                        <Link to='main' className='text-decoration-none FooterHover' > <p className=''>HOME</p></Link>
                                        <Link to='services' className='text-decoration-none FooterHover' > <p className=''>SERVICES</p></Link>
                                        <Link to='about' className='text-decoration-none FooterHover' > <p className=''>ABOUT US</p></Link>
                                        <Link to='product' className='text-decoration-none FooterHover' > <p className=''>PRODUCTS</p></Link>
                                        <Link to='contact' className='text-decoration-none FooterHover'> <p className=''>CONTACT US</p></Link>
                                    </div>
                                </nav>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 text-white ">
                            <div className='mb-2' >
                                <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white mt-3' style={{ fontSize: "24px", fontWeight: "800" }} >Locations</h6>
                            </div>
                            <div className='mt-3'>
                                <div className='pt-3 fontFamilyHeader1 ' style={{ color: "#ccdbeb", fontSize: "16px", lineHeight: "19px", fontWeight: "400" }}>
                                    <h4 className='mb-3 mt-1 text-white' style={{ fontSize: "21px", fontWeight: "600" }} > Pune , Kothrud </h4>
                                    <p className='mb-3 pb-3' style={{ lineHeight: "30px" }} >
                                        Near  Lodha Hospital <br />
                                        Kothrud Pune 
                                    </p>
                                    <a href='tel:9890050512' className='text-decoration-none NumberHover ' style={{ cursor: "pointer" }}> <h6 className='mb-3'><i class="fa-solid fa-phone me-3"></i>+91 9890050512</h6></a>
                                    <a href='mailto:amitdhepe2921@gmail.com' className='text-decoration-none EmailHover' style={{ cursor: "pointer" }} > <h6 className='mb-2'><i class="fa-solid fa-envelope me-3"></i>amitdhepe2921@gmail.com</h6></a>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 text-white ">
                            <div className='mb-2' >
                                <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white mt-3 ' style={{ fontSize: "24px", fontWeight: "800" }} >Business Hours</h6>
                            </div>
                            <div className='pt-3'>
                                <div className='mt-2 fontFamilyHeader1' style={{ color: "#ccdbeb", fontSize: "16px", lineHeight: "19px", fontWeight: "400" }}>
                                    <h4 className='mb-3' style={{ lineHeight: "33px", fontSize: "16px" }}> Mon-Sat: <br />
                                        08.00 am to 06.00 pm </h4>

                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <div className='text-center mt-5 text-white  h1FontFamilyFooter ' style={{ fontWeight: "400" }} >
                        © 2023 Copyright:  Pashumitra Animal Health Care   All Rights Reserved.
                    </div> */}
                    <div className="d-flex p-3 mt-4 justify-content-between align-items-center" >
                        <div className='text-center  text-white  ' >
                            Copyright © Amit Dhepe 2024, Inc. All Rights Reserved.
                        </div>
                        <div className='text-center  text-white ' style={{ fontSize: '10px',cursor:"pointer" }} >
                            Design and Develop by <a href="https://icloudsoftware.co.in" className="text-white text-decoration-none " style={{fontSize:"12px",fontWeight:"800"}} >@ icloudsoft</a>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid pb-3 p-0 d-sm-none d-block " style={{ backgroundColor: "#32409A" }}>
                <div className="container pt-4 p-3 laila-semibold ">
                    <div className="row  g-4 d-flex justify-content-center">

                        <div className="col-lg-3 col-md-6 text-white ">
                            <div className='pb-2'>
                                <Link to='main' style={{ cursor: "pointer" }} >
                                    <img className='' src={require("../images/Logo3.png")} alt="" />
                                </Link>
                            </div>
                            <div className='mt-2 pt-2'>
                                <p style={{ color: "#ccdbeb", fontSize: "17.5px", lineHeight: "27px", fontWeight: "400" }}>
                                    Indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment so blinded by desire all they cannot foresee.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 text-white ">
                            <div className='mb-2' >
                                <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white ' style={{ fontSize: "24px", fontWeight: "800" }} >Useful Links</h6>
                            </div>
                            <div className=' pt-2'>
                                <nav className='h1FontFamily1 ms-lg-3' style={{ fontSize: "17px", }}>
                                    <div className='mt-1 fontFamilyHeader1 ' style={{ fontSize: "15px", lineHeight: "20px", cursor: "pointer" }}>
                                        <Link to='main' className='text-decoration-none FooterHover' > <p className=''>HOME</p></Link>
                                        <Link to='services' className='text-decoration-none FooterHover' > <p className=''>SERVICES</p></Link>
                                        <Link to='about' className='text-decoration-none FooterHover' > <p className=''>ABOUT US</p></Link>
                                        <Link to='product' className='text-decoration-none FooterHover' > <p className=''>PRODUCTS</p></Link>
                                        <Link to='contact' className='text-decoration-none FooterHover'> <p className=''>CONTACT US</p></Link>
                                    </div>
                                </nav>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 text-white ">
                            <div className='mb-2' >
                                <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white ' style={{ fontSize: "24px", fontWeight: "800" }} >Locations</h6>
                            </div>
                            <div className='mt-2'>
                                <div className='pt-3 fontFamilyHeader1 ' style={{ color: "#ccdbeb", fontSize: "16px", lineHeight: "19px", fontWeight: "600" }}>
                                    <h4 className='mb-3 mt-1 text-white' style={{ fontSize: "21px", fontWeight: "600" }} > Pune - Kothrud </h4>
                                    <p className='mb-3 pb-2' style={{ lineHeight: "27px", fontSize: "15px", fontWeight: "400" }} >
                                        Near  Lodha <br />
                                        Hospital Kothrud Pune
                                    </p>
                                    <a href='tel:9890050512' className='text-decoration-none  pb-1' style={{ fontSize: "15px", fontWeight: "400", color: "#ccdbeb", cursor: "pointer" }}> <h6 className=''><i class="fa-solid fa-phone me-3"></i>9890050512</h6></a>
                                    <a href='mailto:amitdhepe2921@gmail.com' className='text-decoration-none' style={{ fontSize: "15px", fontWeight: "400", color: "#ccdbeb", cursor: "pointer" }}> <h6 className=''><i class="fa-solid fa-envelope me-3"></i>amitdhepe2921@gmail.com</h6></a>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 text-white ">
                            <div className='mb-2' >
                                <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white mt-2 ' style={{ fontSize: "24px", fontWeight: "800" }} >Business Hours</h6>
                            </div>
                            <div className='pt-2'>
                                <div className=' fontFamilyHeader1 ' style={{ color: "#ccdbeb", fontSize: "16px", lineHeight: "19px", fontWeight: "400" }}>
                                    <h4 className='mb-2' style={{ lineHeight: "30px", fontSize: "16px" }}> Mon-Sat: <br />
                                        08.00 am to 06.00 pm </h4>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className=" mt-4  " >
                        <div className=' text-white  ' >
                            Copyright © Amit Dhepe 2024, Inc. All Rights Reserved.
                        </div>
                        <div className='mt-3  text-white ' style={{ fontSize: '12px',cursor:"pointer" }} >
                            Design and Develop by <a href="https://icloudsoftware.co.in" className="text-white text-decoration-none" >@ icloudsoft</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Footer