import React from 'react'

const Header = () => {
    return (

        <section id='main'>
            <div className='container-fluid d-none d-sm-block BGMainImg' style={{ backgroundImage: `url(${require("../images/HomeBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center'}}>
                <div className=" laila-light">
                    <div className="row d-flex justify-content-center align-itmes-center " >
                        <div className="">
                            <div className='VideoMainTag'  >
                                <video autoPlay loop muted className='w-100 VideoMainHeight' style={{ objectFit: "fill" }}  >
                                    <source src={require("../images/demo.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                {/* <h4 className='' style={{ fontSize: "70px", fontWeight: "600", lineHeight: "1.2em", textTransform: "capitalize", color: "#fff", letterSpacing: "1px" }} > Delivered Right <br /> To Your Home & <br /> Office Door. </h4> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid d-sm-none d-block p-0' >

                <video autoPlay loop muted className='w-100 ' style={{ objectFit: "cover", height: '100vh' }}  >
                    <source src={require("../images/demo.mp4")} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                {/* <h4 className='' style={{ fontSize: "70px", fontWeight: "600", lineHeight: "1.2em", textTransform: "capitalize", color: "#fff", letterSpacing: "1px" }} > Delivered Right <br /> To Your Home & <br /> Office Door. </h4> */}

            </div>
        </section>



    )
}

export default Header