import React from 'react'
import Navbar from './Components/Navbar'
import Header from './Components/Header'
import About from './Components/About'
import Contact from './Components/Contact'
import Blog from './Components/Blog'
import Services from './Components/Services'
import Product from './Components/Product'
import Footer from './Components/Footer'
// import Error from './Components/Error'

const App = () => {
  return (
    <div>
      <Navbar />
      <Header />
      <Services />
      <About />
      <Product />
      <Contact />
      <Blog />
      <Footer />
      {/* <Error /> */}
    </div>
  )
}

export default App