import React from 'react'
import { Link } from "react-scroll";


const Contact = () => {
    return (

        <section id='contact'>
            <div className="container-fluid d-none d-sm-block">
                <div className="container p-5 mt-5 laila-semibold">
                    <div className="row d-flex justify-content-center g-4 p-2 FontFamilyMontSerret">

                        <div className='col-lg-6 ' >
                            <div className='text-start mb-5' >
                                <div className='' style={{ paddingBottom: "16px" }} >
                                    <h6 className='laila-bold' style={{ fontSize: "15px", lineHeight: "1.2em", color: "#65cef5" }} >GET IN TOUCH</h6>
                                </div>
                                <h4 className='laila-bold' style={{ fontSize: "48px", lineHeight: "1.2em", color: "#151515" }} > Love to assist <br /> with your enquiry! </h4>
                            </div>
                            <div className='d-flex justify-content-start align-items-center'>
                                <div className='p-3' style={{ backgroundColor: "#65CEF5", borderRadius: "50%", height: "60px", width: "60px" }} >
                                    <i class="fa-sharp fa-solid fa-phone ms-1 mt-1" style={{ fontSize: "20px", color: "#fff" }} ></i>
                                </div>
                                <div className='ms-4 '>
                                    <h2 className='' style={{ fontSize: "20px", lineHeight: "34px", fontWeight: "800", marginBottom: "8px", color: "#65cef5" }} >Quick Contact</h2>
                                    <a href='tel:9890050512' className='text-decoration-none'><p className='' style={{ fontSize: "21px", lineHeight: "27px", fontWeight: "600", marginBottom: "8px", color: "#151515" }}>
                                        +91 9890050512
                                    </p>
                                    </a>
                                </div>
                            </div>
                            <p className='mt-3 pt-2' style={{ fontSize: "16px", lineHeight: "26px", color: "#585858", fontWeight: "600" }}>
                                Please feel free to call us with any questions or to set <br />
                                up your account.
                            </p>
                            <div className='mt-3 pt-3 d-flex align-items-center'>
                                <a href='tel:9890050512' className='text-decoration-none' >
                                    <button className='btn btn CALLBACK' style={{ backgroundColor: "#004DA1", color: "#fff", borderRadius: "30px", fontWeight: "800", fontSize: "14px", lineHeight: "45px" }} >
                                        CALL BACK
                                    </button>
                                </a>

                                <a href="https://wa.me/9890050512">
                                    <div className='ms-4' style={{ backgroundColor: "#1BD741", borderRadius: "50%", height: "60px", width: "60px", cursor: "pointer" }} >
                                        <i class="fa-brands fa-whatsapp ms-3 " style={{ fontSize: "30px", color: "#fff", marginTop: "15px" }} ></i>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-6  p-0'>
                            <div className='m-0'>
                                <iframe className='w-100 m-0' title="gmap" style={{ height: "500px" }} src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3783.5786752858444!2d73.81129497519122!3d18.502734182587393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDMwJzA5LjgiTiA3M8KwNDgnNDkuOSJF!5e0!3m2!1sen!2sin!4v1709021823364!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                                </iframe>
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3783.5786752858444!2d73.81129497519122!3d18.502734182587393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDMwJzA5LjgiTiA3M8KwNDgnNDkuOSJF!5e0!3m2!1sen!2sin!4v1709021823364!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container-fluid d-sm-none d-block">
                <div className="container mt-5 pb-4 laila-semibold">
                    <div className="row d-flex justify-content-center g-4 p-2 FontFamilyMontSerret">

                        <div className='col-lg-6 ' >
                            <div className='text-start mb-4' >
                                <div className='' style={{ paddingBottom: "16px" }} >
                                    <h6 className='laila-bold' style={{ fontSize: "15px", lineHeight: "1.2em", color: "#65cef5" }} >GET IN TOUCH</h6>
                                </div>
                                <h4 className='laila-bold' style={{ fontSize: "38px", lineHeight: "1.2em", color: "#151515" }} > Love to assist with your enquiry! </h4>
                            </div>

                            <div className='d-flex justify-content-start align-items-center pt-2'>
                                <div className='p-3' style={{ backgroundColor: "#65CEF5", borderRadius: "50%", height: "60px", width: "60px" }} >
                                    <i class="fa-sharp fa-solid fa-phone ms-1 mt-1" style={{ fontSize: "20px", color: "#fff" }} ></i>
                                </div>
                                <div className='ms-4 '>
                                    <h2 className='' style={{ fontSize: "20px", lineHeight: "34px", fontWeight: "800", marginBottom: "8px", color: "#65cef5" }} >Quick Contact</h2>
                                    <a href='tel:9890050512' className='text-decoration-none'>
                                        <p className='' style={{ fontSize: "20px", lineHeight: "27px", fontWeight: "600", marginBottom: "8px", color: "#151515" }}>
                                            9890050512
                                        </p>
                                    </a>
                                </div>
                            </div>
                            <p className='mt-3 pt-2' style={{ fontSize: "16px", lineHeight: "26px", color: "#585858", fontWeight: "600" }}>
                                Please feel free to call us with any questions or to set
                                up your account.
                            </p>
                            <div className='mt-3 pt-3 d-flex align-items-center'>
                                <a href='tel:9890050512' className='text-decoration-none' >
                                    <button className='btn btn CALLBACK' style={{ backgroundColor: "#004DA1", color: "#fff", borderRadius: "30px", fontWeight: "800", fontSize: "14px", lineHeight: "45px" }} >
                                        CALL BACK
                                    </button>
                                </a>

                                <a href="https://wa.me/9890050512">
                                    <div className='ms-4' style={{ backgroundColor: "#1BD741", borderRadius: "50%", height: "60px", width: "60px", cursor: "pointer" }} >
                                        <i class="fa-brands fa-whatsapp ms-3 " style={{ fontSize: "30px", color: "#fff", marginTop: "15px" }} ></i>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-6  p-0'>
                            <div className='m-0'>
                                <iframe className='w-100 m-0' title="gmap" style={{ height: "500px" }} src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3783.5786752858444!2d73.81129497519122!3d18.502734182587393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDMwJzA5LjgiTiA3M8KwNDgnNDkuOSJF!5e0!3m2!1sen!2sin!4v1709021823364!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                                </iframe>
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3783.5786752858444!2d73.81129497519122!3d18.502734182587393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDMwJzA5LjgiTiA3M8KwNDgnNDkuOSJF!5e0!3m2!1sen!2sin!4v1709021823364!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact