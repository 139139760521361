import React, { useState } from 'react'
import { Link } from "react-scroll";
import "./Comman.css"


const Navbar = () => {


    return (

        <>

            <section>
                <div className="container-fluid mainTopMargin  d-none d-lg-block d-block " style={{ backgroundColor: "#004DA1" }} >
                    <div className="container-lg laila-bold p-3 pb-1" >
                        <div className="row ">
                            <ul class=" d-flex justify-content-start align-items-center" style={{ listStyle: "none" }} >
                                <li className="PaddingLeft" style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "500", color: "#fff" }} >
                                    Immediate Delivery <a className="text-decoration-none NumberHover ms-3" style={{ fontSize: "14px", cursor: "pointer" }} href='tel:9890050512' > <i class="fa-solid fa-phone me-2 " style={{ color: "#fff", fontSize: "15px" }} > </i> +91  9890050512 </a>  &nbsp; |
                                </li>

                                <li className="PaddingLeft" style={{ fontSize: "15px", lineHeight: "24px", fontWeight: "500", color: "#fff" }} >
                                    <i class="fa-solid fa-clock me-1" style={{ color: "#fff", fontSize: "15px" }} ></i> Mon - Sat : 08.00 to 06.00  &nbsp; |
                                </li>

                                <li className="PaddingLeft ms-lg-5" style={{ fontSize: "15px", lineHeight: "24px", fontWeight: "500", color: "#fff", letterSpacing: "1px" }} >
                                    <i class="fa-solid fa-envelope me-1 " style={{ color: "#fff", fontSize: "15px" }} > </i> Enquire? <a className="text-decoration-none EmailHover ms-2" href='mailto:amitdhepe2921@gmail.com' >  Send Your Mail </a>  &nbsp;
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-none " style={{ backgroundColor: "#004DA1" }} >
                    <div className="container"  >
                        <ul class=" d-flex justify-content-lg-center " style={{ padding: "14px" }} >
                            <li className=" d-flex justify-content-lg-center ps-lg-5 " >
                                <Link className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > For Bookings (+91) 9527468898 </span> </Link>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <Link className="text-decoration-none" to='tel:9527468898' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > Customer Care (+91) 9527468898  </span> </Link>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <Link className="text-decoration-none" to='mailto:info@soft.co.in' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} >  info@icloudsoft.com  </span> </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div>
                <nav class="navbar navbar-expand-lg navbar-light p-0  m-0 mainMargin d-none d-sm-block" style={{ backgroundImage: `url(${require("../images/HomeBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top,top' }}>
                    <div class="container laila-semibold Padding-Main" style={{ backgroundColor: "white", borderRadius: "0px 0px 15px 15px" }}>
                        <Link className='nav-link ' style={{ cursor: "pointer" }} to='main'>
                            <img className='' style={{ height: "3rem" }} src={require("../images/Logo2.png")} alt="" />
                        </Link>
                        <a class="navbar-brand" href="#"></a>
                        <button class="navbar-toggler" style={{ backgroundColor: "#004da1", color: "#fff" }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            {/* <span class="navbar-toggler-icon bg-light"  > </span> */}
                            <i class="fa-sharp fa-solid fa-equals p-2"></i>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNav">
                            <ul class="navbar-nav d-flex justify-content-start  w-100 ">
                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' style={{ cursor: "pointer", color: "#65cef5" }} to='main'>
                                        HOME
                                    </Link>
                                </li>

                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' to='services'>
                                        SERVICES
                                    </Link>
                                </li>

                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' to='about'>
                                        ABOUT
                                    </Link>
                                </li>

                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' to='product'>
                                        PRODUCTS
                                    </Link>
                                </li>

                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' to='contact'>
                                        CONTACT
                                    </Link>
                                </li>

                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' to='client'>
                                        CLIENT
                                    </Link>
                                </li>

                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' style={{ backgroundColor: "#004DA1", cursor: "pointer", color: "#fff", fontSize: "15px", borderRadius: "50%", height: "43px", width: "43px" }} >
                                        <i class="fa-solid fa-magnifying-glass ms-1 " style={{ marginTop: "6px" }} ></i>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>

                <nav class="navbar navbar-expand-lg navbar-light  mainMargin d-sm-none d-block" >
                    <div class="container p-3 laila-semibold" style={{ backgroundColor: "white", borderRadius: "0px 0px 15px 15px" }}>
                        <Link className='nav-link ' to='main'>
                            <img className='w-100' src={require("../images/Logo2.png")} alt="" />
                        </Link>
                        <a class="navbar-brand" href="#"></a>
                        <button class="navbar-toggler" style={{ backgroundColor: "#004da1", color: "#fff" }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            {/* <span class="navbar-toggler-icon bg-light"  > </span> */}
                            <i class="fa-sharp fa-solid fa-equals p-2"></i>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNav">
                            <ul class="navbar-nav d-flex justify-content-start  w-100 mt-3 pb-2">
                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' style={{ cursor: "pointer", color: "#65cef5" }} to='main'>
                                        HOME
                                    </Link>
                                </li>

                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' style={{ cursor: "pointer", color: "#151515", fontSize: "15px" }} to='services'>
                                        SERVICES
                                    </Link>
                                </li>

                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' style={{ cursor: "pointer", color: "#151515", fontSize: "15px" }} to='blog'>
                                        BLOG
                                    </Link>
                                </li>

                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' style={{ cursor: "pointer", color: "#151515", fontSize: "15px" }} to='about'>
                                        ABOUT
                                    </Link>
                                </li>

                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' style={{ cursor: "pointer", color: "#151515", fontSize: "15px" }} to='product'>
                                        PRODUCTS
                                    </Link>
                                </li>

                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' style={{ cursor: "pointer", color: "#151515", fontSize: "15px" }} to='contact'>
                                        CONTACT
                                    </Link>
                                </li>

                                <li class="nav-item ps-lg-4">
                                    <Link className='nav-link ' style={{ backgroundColor: "#004DA1", cursor: "pointer", color: "#fff", fontSize: "18px", borderRadius: "50%", height: "53px", width: "53px" }} >
                                        <i class="fa-solid fa-magnifying-glass ms-3 " style={{ marginTop: "8px" }} ></i>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>
            </div>

        </>

    )
}

export default Navbar